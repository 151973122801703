'use client';

import type { JsonObject } from 'type-fest';
import { context, isJsonKeyRequestScoped } from '../context';

export interface SetGlobalContextValueProps {
  data: JsonObject;
}

export function SetGlobalContextValueClient({ data }: SetGlobalContextValueProps): null {
  for (const [key, value] of Object.entries(data)) {
    if (isJsonKeyRequestScoped(key)) {
      context.setRequestValue(
        key,
        // no caching on the client
        () => value
      );
      continue;
    }

    context.setGlobalValue(key, value);
  }

  return null;
}
