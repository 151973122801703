//! server-and-client

import { memoize, once } from 'lodash';
import Cookies from 'js-cookie';
import { captureException } from '@sentry/nextjs';
import { context } from '@/contexts/GlobalContext';

/**
 * Log error to Sentry
 *
 * @note When this is used inside components that could re-render often and spam with errors
 * Use `logError()` with param `once`.
 */
const logSentryError = (error: string | Error, extra: Record<string, unknown> = {}): void => {
  console.error(error, extra);
  if (context.get<boolean>('isSentryEnabled')) {
    const err = error instanceof Error ? error : new Error(error);
    const debugData = { ...extra };
    const fixterSessionId = Cookies.get('fixterSessionId');
    if (fixterSessionId) debugData.sessionId = fixterSessionId;
    captureException(err, { extra: debugData });
  }
};

/**
 * Log error ONLY once to Sentry.
 * Useful when re-renders would spam same error.
 *
 * @note SSR this will only log once per instance restart and CSR once for each client.
 */
const logErrorOnce = memoize((...params: Parameters<typeof logSentryError>) =>
  once(() => logSentryError(...params))()
);

/**
 * Log error to Sentry
 *
 * @param logOnce Logs the error only once.\
 * Useful when this is used inside Client Components that could re-render often and spam with errors.
 */
export const logError = (error: string | Error, extra: Record<string, unknown> = {}, logOnce = true): void =>
  logOnce ? logErrorOnce(error, extra) : logSentryError(error, extra);
