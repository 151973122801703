//! server-and-client

import type { JsonValue } from 'type-fest';
import { memoize, once } from 'lodash';

/**
 * @note we don't use the `ServerLogger` here because this context is used on Client Components also.
 * @todo use Sentry logger
 */
const logErrorOnce = memoize((...args: unknown[]) => once(() => console.error(...args))());

type JsonKeyRequestScoped = `${string}()`;
type JsonValueRequestScoped = () => JsonValue;

export const isJsonKeyRequestScoped = (key: string): key is JsonKeyRequestScoped => key.endsWith('()');

type GlobalContextValue = JsonValue | JsonValueRequestScoped;

class GlobalContext extends Map<string, GlobalContextValue> {
  /**
   * @note all values should be explicitly set to avoid confusion between NOT set values and values set to `false | null | ''`.
   */
  get<T extends JsonValue = JsonValue>(key: string): T | undefined {
    const val = super.get(key);
    if (typeof val === 'undefined') {
      /**
       * @note log an error because asking for a value that was not defined usually means an oversight to set the context value.
       * If you need a "non-value" it has to be explicitly set to `null` to state it was intentional.
       */
      logErrorOnce(`GlobalContext: key '${key}' not found`);
      return undefined;
    }

    if (isJsonKeyRequestScoped(key)) {
      if (typeof val !== 'function') {
        logErrorOnce(`GlobalContext: request scoped key '${key}' value is not a function`);
        return undefined;
      }
      const reqScopedFn = val;
      return reqScopedFn() as T;
    }

    return val as T;
  }

  /**
   * Value set globally for all requests to share.
   *
   * @note consider using the `<SetGlobalContextValue />` component to set values.
   */
  setGlobalValue(key: string, value: JsonValue): this {
    return super.set(key, value);
  }

  /**
   * Value set only for one request.
   *
   * @note consider using the `<SetGlobalContextValue />` component to set values.
   *
   * @note request scoping is not implemented in this function, the caller of the function has to provide this functionality.
   * eg.
   * - App Router: value passed wrapped in `React.cache()`
   * - Pages Router: call this inside a server function.
   */
  setRequestValue(key: JsonKeyRequestScoped, fn: JsonValueRequestScoped): this {
    return super.set(key, fn);
  }

  /**
   * @deprecated use `setGlobalValue()` or `setRequestValue()` instead.
   * @note this method signature is too loose and can lead to fatal errors due to type mismatch
   */
  set(key: string, value: GlobalContextValue): this {
    return super.set(key, value);
  }
}

/**
 * Global singleton used to set data to be used by both the **browser and server**.
 *
 * This is an alternative to React Context for when we want to read context data outside of React components.
 * eg. a client util function that needs to read a config value.
 *
 * @important
 * App Router: To set values that are available on the server and the client use the `<SetGlobalContextValue />` component.
 *
 * @warn Normal keys are global and will maintain state between requests on server.
 *
 * If you need a value to be request scoped, set format key as `key()` and provide a request scoped function that returns the value.\
 * eg. `context.setRequestValue('requestId()', cache(() => 1234))`
 *
 * @note This is sent over the network so make sure to set values only on the pages that use them.
 * Otherwise the payload we send to the client will increase with unused data and degrade performance.
 */
export const context = new GlobalContext();
